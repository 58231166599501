@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Solway:wght@400;500;700&display=swap");

@font-face {
  font-family: SkModernist;
  src: url("./fonts/Sk-Modernist/Sk-Modernist-Regular.otf") format("opentype");
}

@font-face {
  font-family: SkModernist;
  font-weight: bold;
  src: url("./fonts/Sk-Modernist/Sk-Modernist-Bold.otf") format("opentype");
}

@font-face {
  font-family: SkModernist;
  font-weight: lighter;
  src: url("./fonts/Sk-Modernist/Sk-Modernist-Mono.otf") format("opentype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

blockquote {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 181.5%;
  letter-spacing: -0.01em;
  font-style: normal;
  margin: 0;
  font-weight: 500;
  text-align: justify;
}

blockquote::before {
  content: "linguo.id - ";
  opacity: 80%;
}

.highlighted-text {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 14.5px;
  margin-top: 5px;
}

.block-content {
  font-size: 1.1875rem;
  line-height: 1.5;
}
