@-webkit-keyframes zoom {
    0% { -webkit-transform: scale(1) }
    100% { -webkit-transform: scale(1.2) }
}

@keyframes zoom {
    0% { transform: scale(1); }
    100% { transform: scale(1.2)  }
}

@-moz-keyframes zoom {
    0% { -moz-transform: scale(1) }
    100% { -moz-transform: scale(1.2) }
}

@-o-keyframes zoom {
    0% { -o-transform: scale(1) } 
    100% { -o-transform: scale(1.2) } 
}